.VideoCallMain {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
}

.VideoSectionMain {
    width: 100%;
    position: relative;
    height: 80vh;
    flex-grow: 1;
}

.doctorData {
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(4.083333492279053px);
    padding: 8px 20px;
    z-index: 1;
}

.fullScreenModeIcon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(4.083333492279053px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    cursor: pointer;
}

.videoScreens {
    width: 100%;
    height: 100%;
    background: #EDEDED;
    border-radius: 6px;
    flex-grow: 1;
    position: relative;
}

.controlesBtns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 1rem;
}

.roundedBtns {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--Akos-Dark-grey, #767676);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.roundedBtns.active {
    background: #D42A2A;
}

.callEndBtn {
    border-radius: 8px;
    background: #D42A2A;
    display: flex;
    padding: 6px 24px;
    align-items: center;
    gap: 18px;
    color: var(--Akos-white, #FFF);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    cursor: pointer;
}

.PublisherConnected {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 270px;
    height: 181px;
    z-index: 9;
    background: #fff;
    border-radius: 6px;
}

#subscriber {
    width: 100%;
    height: 100%;
}

.SubscriberConnected {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 10px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.SubscriberConnected .OT_subscriber {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
}

@media (max-width: 600px) {
    .SubscriberConnected {
        grid-template-columns: 1fr;
    }

    .PublisherConnected {
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 10px;
    }
}

@media (min-width: 601px) and (max-width: 900px) {
    .SubscriberConnected {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
}

@media (min-width: 901px) {
    .SubscriberConnected {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}


/* chat section start */
.chatSection {
    border-radius: 16px;
    border: 1px solid var(--Akos-Light-Grey, #C9C9C9);
    background: #FBFBFB;
}

.chatMainDiv {
    position: relative;
    height: 100%;
}

.sendMessageBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 8px;
    background: var(--Primary-color-Primary-color200, #0562CC);
    cursor: pointer;
}

.chatFooter {
    position: absolute;
    bottom: 0;
    padding: 10px;
    width: 100%;
}

.custumTextFieldChat {
    padding: 14px 10px !important;
}

.chatHeader {
    border-radius: 16px 16px 0px 0px;
    border: 1px solid #DFE0EB;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.chatDocNameImg img {
    height: 50px;
    width: 50px;
    object-fit: contain;
    border-radius: 50%;
}

.chatDocNameImg {
    display: flex;
    gap: 10px;
}

.chatDocNameImg .namePro h3 {
    color: var(--Accent-color-Accent-color200, #1D2225);
    font-size: 14px;
    font-weight: 700;
}

.chatDocNameImg .namePro p {
    color: var(--Accent-color-Accent-color200, #1D2225);
    font-size: 13px;
}

.chatBody {
    padding: 1rem;
    overflow: auto;
    max-height: calc(80vh - 75px);
}

.chatBody::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}
.patientChat, .doctorChat {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 30px;
}
.patientChat:first-child, .doctorChat:first-child{
    margin-top: 0;
}
.doctorChat{
    justify-content: flex-start;
}
.patientMsg {
    border-radius: 10px;
    background: var(--Primary-color-Primary-color200, #0562CC);
    padding: 10px;
    text-align: right;
}
.patientMsg p{
    color: #FFF;
}
.patientMsg span{
    text-align: right;
    font-size: 12px;
    color: #FFF;
    margin-top: 10px;
}
.patientImg{
    width: 50px;
    object-fit: contain;
}

.DoctorMsg{
    border-radius: 10px;
    border: 1px solid var(--Primary-color-Primary-color200, #0562CC);
    background: #FFF;
    padding: 10px;
    text-align: left;
}
.DoctorMsg p{
    color: #0562CC;
}
.DoctorMsg span{
    text-align: right;
    font-size: 12px;
    color: #0562CC;
    margin-top: 10px;
}
.DoctorMsg, .patientMsg{
    max-width: 60%;
    width: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.noBgForThis{
    background: none !important;
    border: none !important;
}
/* chat section end */
/* New Video Call end */

.waitingStatusMain {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 100px);
    gap: 16px;
}

.waitingStatusMain h3 {
    font-size: 20px;
}